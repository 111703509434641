"use client";

import type { GiveFeedbackRequest } from "@/app/feedback/route";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { useUser } from "@clerk/nextjs";
import { PaperPlaneIcon } from "@radix-ui/react-icons";
import { PopoverClose } from "@radix-ui/react-popover";
import { useState } from "react";
import { LoadingIcon } from "../ui/loading-icon";
import { Separator } from "../ui/separator";
import { toast } from "../ui/use-toast";

export function FeedbackWidgetButton() {
  const [sending, setSending] = useState(false);
  const [text, setText] = useState("");

  const { user } = useUser();

  async function sendFeedback() {
    const feedback = text.trim();
    if (!feedback.trim()) {
      toast({
        title: "Feedback is empty",
        description: "Please enter some feedback before sending.",
        variant: "destructive",
      });
    }

    // Get current URL
    const url = window.location.href;

    setSending(true);
    const res = await fetch("/feedback", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        feedback,
        url,
        userName: user?.fullName ?? user?.primaryEmailAddress?.emailAddress,
      } as GiveFeedbackRequest),
    });
    setSending(false);
    if (res.ok) {
      toast({
        title: "Feedback sent",
        description: "Thank you for your feedback!",
      });
      setText("");
    } else {
      const text = await res.text();
      toast({
        title: "Failed to send feedback",
        description: `${res.status} ${res.statusText}: ${text}`,
        variant: "destructive",
      });
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">Feedback</Button>
      </PopoverTrigger>
      <PopoverContent className="w-96 px-0 pt-xl pb-base">
        <div className="space-y-base w-full">
          <div className="mx-xl">
            <Textarea
              className="h-[80px]"
              placeholder="Ideas on how to improve this page."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <Separator />
          <div className="mx-xl flex gap-xl justify-between">
            <PopoverClose asChild>
              <Button variant="outline">Cancel</Button>
            </PopoverClose>
            <div className="flex gap-lg">
              <Button variant="outline" onClick={() => setText("")}>
                Clear
              </Button>
              <Button
                className="gap-base"
                disabled={sending}
                onClick={() => void sendFeedback()}
              >
                {sending ? <LoadingIcon /> : <PaperPlaneIcon />} Send Feedback
              </Button>
            </div>
          </div>
          <div className="mx-xl">
            <Label className="text-text-secondary">
              Have a technical issue?
              <br />
              Contact{" "}
              <a
                href="mailto:info@lavareach.com"
                className="text-blue-500 hover:underline"
                target="_blank"
              >
                LavaReach support
              </a>{" "}
              or join our{" "}
              <a
                href="https://discord.gg/Y8su3YCm3n"
                className="text-blue-500 hover:underline"
                target="_blank"
              >
                Discord server
              </a>
              .
            </Label>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
