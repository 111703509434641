export default function SequenceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.25 14.25H4.65C3.80992 14.25 3.38988 14.25 3.06901 14.0865C2.78677 13.9427 2.5573 13.7132 2.41349 13.431C2.25 13.1101 2.25 12.6901 2.25 11.85V6.15C2.25 5.30992 2.25 4.88988 2.41349 4.56901C2.5573 4.28677 2.78677 4.0573 3.06901 3.91349C3.38988 3.75 3.80992 3.75 4.65 3.75H13.35C14.1901 3.75 14.6101 3.75 14.931 3.91349C15.2132 4.0573 15.4427 4.28677 15.5865 4.56901C15.75 4.88988 15.75 5.30992 15.75 6.15V8.25M15.455 6.19672L11.6624 8.72512C10.7002 9.36664 10.219 9.68739 9.69899 9.81201C9.23942 9.92214 8.76033 9.92214 8.30076 9.81201C7.78073 9.68739 7.29959 9.36664 6.33732 8.72512L2.3606 6.07397M13.8732 11.784C13.3484 11.1996 12.4732 11.0424 11.8156 11.5775C11.158 12.1126 11.0655 13.0072 11.5819 13.6401C12.0983 14.273 13.8732 15.75 13.8732 15.75C13.8732 15.75 15.6482 14.273 16.1646 13.6401C16.681 13.0072 16.5997 12.107 15.9308 11.5775C15.2619 11.048 14.398 11.1996 13.8732 11.784Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
