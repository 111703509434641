/* eslint-disable @next/next/no-img-element */
"use client";

import { SubscriptionContext } from "@/hooks/subscription-context";
import { snakeToTitleCase } from "@/utils/string_formating";
import {
  useAuth,
  useClerk,
  useOrganization,
  useOrganizationList,
  useUser,
} from "@clerk/nextjs";
import { ExitIcon } from "@radix-ui/react-icons";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { LoadingIcon } from "../ui/loading-icon";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";
import { Skeleton } from "../ui/skeleton";
import { useIsVisible } from "../utils/use-visible";
import { CreateOrganizationDialog } from "./create-organization-dialog";
import { ManageAccountLinkButton } from "./manage-account-link-button";
import { ManageOrganizationLinkButton } from "./manage-organization-link-button";

export interface ProfileSettingsProps {
  hidePersonal?: boolean;
}

const ProfileSettings = ({ hidePersonal }: ProfileSettingsProps) => {
  const { orgId, isLoaded: authIsLoaded, signOut } = useAuth();
  const { user } = useUser();
  const { membership } = useOrganization();
  const subscription = useContext(SubscriptionContext);

  const [signingOut, setSigningOut] = useState(false);

  const isLoaded = authIsLoaded && user;
  if (!isLoaded) return <Skeleton className="w-8 h-8 rounded-full" />;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="flex items-center gap-xs ">
          <img
            src={user.imageUrl}
            alt="Profile"
            className="w-8 h-8 object-cover object-center rounded-full filter hover:brightness-90 active:brightness-75 transition-all"
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-auto" align="end">
        <div className="px-xl pt-xl pb-lg rounded-t-lg flex flex-col gap-base items-stretch">
          <div className="flex gap-lg items-center">
            <img
              src={user.imageUrl}
              alt="Profile"
              className="w-10 h-10 object-cover object-center rounded-full"
            />
            <div className="flex flex-col">
              <Label>{user.fullName}</Label>
              <Label className="text-muted-foreground">
                {user.primaryEmailAddress?.emailAddress}
              </Label>
            </div>
          </div>
          <div className="flex justify-start gap-lg">
            <ManageAccountLinkButton />
            <Button
              className="gap-base"
              disabled={signingOut}
              onClick={() => {
                setSigningOut(true);
                void signOut({ redirectUrl: "/" });
              }}
              variant="outline"
            >
              {signingOut ? (
                <LoadingIcon />
              ) : (
                <ExitIcon className="text-text-secondary" />
              )}
              Sign Out
            </Button>
          </div>
        </div>
        <Separator />
        <div className="px-xl pt-xl pb-lg rounded-t-lg flex flex-col gap-base items-start">
          {membership ? (
            <>
              <div className="flex gap-lg items-center">
                <img
                  src={membership.organization.imageUrl}
                  alt="Organization image"
                  className="w-10 h-10 object-cover object-center rounded-md"
                />
                <div className="flex flex-col">
                  <Label>{membership.organization.name}</Label>
                  <Label className="text-muted-foreground">
                    {snakeToTitleCase(membership.role)}
                  </Label>
                </div>
              </div>
              <ManageOrganizationLinkButton />
            </>
          ) : (
            <div className="flex gap-lg items-center">
              <img
                src={user.imageUrl}
                alt="Personal account image"
                className="w-10 h-10 object-cover object-center rounded-md"
              />
              <div className="flex flex-col">
                <Label>Personal Account</Label>
              </div>
            </div>
          )}
        </div>
        <Separator />
        <OrganizationList hidePersonal={hidePersonal} orgId={orgId} />
        {subscription?.subscription?.enableCreateOrg === true && (
          <>
            <Separator />
            <div className="bg-background-secondary px-xl py-lg rounded-b-lg">
              <CreateOrganizationDialog />
            </div>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
};
ProfileSettings.displayName = "ProfileSettings";
export { ProfileSettings };

interface OrganizationListProps {
  hidePersonal: boolean | undefined;
  orgId: string | null | undefined;
}

function OrganizationList({ hidePersonal, orgId }: OrganizationListProps) {
  const { setActive } = useClerk();
  // If null, it means loading personal account
  const [loadingId, setLoadingId] = useState<string | null | undefined>();

  const { user } = useUser();
  const orgList = useOrganizationList({
    userMemberships: { pageSize: 100, infinite: true },
  });
  const { membership } = useOrganization();

  const bottomDivRef = useRef<HTMLDivElement>(null);
  const bottomIsVisible = useIsVisible(bottomDivRef);

  useEffect(() => {
    if (
      bottomIsVisible &&
      !orgList.userMemberships.isLoading &&
      orgList.userMemberships.hasNextPage
    ) {
      orgList.userMemberships.fetchNext();
    }
  }, [bottomIsVisible, orgList.userMemberships.isLoading]);

  if (!user || !orgList.isLoaded) {
    return <Skeleton className="w-full h-[40px]" />;
  }

  function switchToOrg(organizationId: string | null) {
    setLoadingId(organizationId);
    void setActive({ organization: organizationId }).then(() => {
      window?.location?.reload();
      setLoadingId(undefined);
    });
  }

  return (
    <div className="bg-background-secondary flex flex-col items-stretch max-h-[360px] overflow-y-auto">
      {membership && !hidePersonal && (
        <Button
          className="px-xl py-sm h-auto justify-start flex gap-lg items-center"
          variant="ghost"
          onClick={() => switchToOrg(null)}
        >
          <img
            src={user.imageUrl}
            alt="Personal account image"
            className="w-8 h-8 m-1 object-cover object-center rounded-md"
          />
          <Label className="cursor-pointer">
            {loadingId === null ? <LoadingIcon /> : "Personal Account"}
          </Label>
        </Button>
      )}
      {orgList.userMemberships.data
        .filter(({ organization }) => organization.id !== orgId)
        .map(({ organization, role }) => (
          <Button
            key={organization.id}
            className="px-xl py-sm h-auto justify-start flex gap-lg items-center text-start"
            variant="ghost"
            onClick={() => switchToOrg(organization.id)}
          >
            <img
              src={organization.imageUrl}
              alt="Organization image"
              className="w-8 h-8 m-1 object-cover object-center rounded-md"
            />
            <div className="flex flex-col">
              <Label className="cursor-pointer">
                {loadingId === organization.id ? (
                  <LoadingIcon />
                ) : (
                  organization.name
                )}
              </Label>
              <Label className="text-muted-foreground cursor-pointer">
                {snakeToTitleCase(role)}
              </Label>
            </div>
          </Button>
        ))}
      <div ref={bottomDivRef} className="min-h-[0.1px]">
        {orgList.userMemberships.isLoading && (
          <Skeleton className="w-full h-8" />
        )}
      </div>
    </div>
  );
}
