"use client";

import { GearIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { Button } from "../ui/button";

/**
 * Important: This is for managing a user account, not for an account in a list.
 */
export const ManageAccountLinkButton = () => {
  return (
    <Link href="/dashboard/settings/user-profile">
      <Button variant="outline" className="gap-base">
        <GearIcon className="text-text-secondary" />
        Settings
      </Button>
    </Link>
  );
};
