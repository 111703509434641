"use client";

import { GearIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { Button } from "../ui/button";

export const ManageOrganizationLinkButton = () => {
  return (
    <Link href="/dashboard/settings/organization-profile">
      <Button variant="outline" className="gap-base">
        <GearIcon className="text-text-secondary" />
        Manage Organization
      </Button>
    </Link>
  );
};
