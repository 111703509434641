export default function ListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M6 3.75004C5.25874 3.75062 4.87019 3.76003 4.56901 3.91349C4.28677 4.0573 4.0573 4.28677 3.91349 4.56901C3.75 4.88988 3.75 5.30992 3.75 6.15V13.35C3.75 14.1901 3.75 14.6101 3.91349 14.931C4.0573 15.2132 4.28677 15.4427 4.56901 15.5865C4.88988 15.75 5.30992 15.75 6.15 15.75H11.85C12.6901 15.75 13.1101 15.75 13.431 15.5865C13.7132 15.4427 13.9427 15.2132 14.0865 14.931C14.25 14.6101 14.25 14.1901 14.25 13.35V6.15C14.25 5.30992 14.25 4.88988 14.0865 4.56901C13.9427 4.28677 13.7132 4.0573 13.431 3.91349C13.1298 3.76003 12.7413 3.75062 12 3.75004M6 3.75004V5.25H12V3.75004M6 3.75004V3.53033C6 3.19077 6.13489 2.86511 6.375 2.625C6.61511 2.38489 6.94077 2.25 7.28033 2.25H10.7197C11.0592 2.25 11.3849 2.38489 11.625 2.625C11.8651 2.86511 12 3.19077 12 3.53033V3.75004M11.25 9H9M11.25 12H9M6.75 9H6.7575M6.75 12H6.7575"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
