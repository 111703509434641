"use client";

import NextLink from "next/link";
import { useIsBlocked } from "./navigation-block";

/**
 * A custom Link component that wraps Next.js's next/link component.
 * For blocking navigation, it uses the `NavigationBlockerProvider` context.
 * https://github.com/vercel/next.js/discussions/41934#discussioncomment-8996669
 */
export function NavBlockerLink({
  href,
  children,
  ...rest
}: Parameters<typeof NextLink>[0]) {
  // const router = useRouter();
  const isBlocked = useIsBlocked();

  return (
    <NextLink
      href={href}
      onClick={(e) => {
        e.preventDefault();
        // Cancel navigation
        if (isBlocked) {
          if (!window.confirm("Do you really want to leave without saving?")) {
            return;
          } else {
            window?.location?.replace(href as unknown as string);
          }
        } else {
          // Allow navigation
          window?.location?.replace(href as unknown as string);
        }
      }}
      {...rest}
    >
      {children}
    </NextLink>
  );
}
