"use client";

import { CreateOrganization } from "@clerk/nextjs";
import { PlusIcon } from "@radix-ui/react-icons";
import { Button } from "../ui/button";
import { CompactDialogContent, Dialog, DialogTrigger } from "../ui/dialog";

export const CreateOrganizationDialog = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-base">
          <PlusIcon className="text-text-secondary" />
          Create Organization
        </Button>
      </DialogTrigger>
      <CompactDialogContent className="md:w-fit bg-transparent border-none shadow-none">
        <CreateOrganization
          routing="hash"
          afterCreateOrganizationUrl="/dashboard"
        />
      </CompactDialogContent>
    </Dialog>
  );
};
